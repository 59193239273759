import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/one'));
const PageTwo = lazy(() => import('src/pages/dashboard/two'));
const PageThree = lazy(() => import('src/pages/dashboard/three'));
const PageFour = lazy(() => import('src/pages/dashboard/four'));
const PageFive = lazy(() => import('src/pages/dashboard/five'));
const PageSix = lazy(() => import('src/pages/dashboard/six'));
const Sports = lazy(() => import('src/pages/dashboard/sports/sports'));
const EventDetail = lazy(() => import('src/pages/dashboard/sports/event-detail'));
const Bets = lazy(() => import('src/pages/dashboard/bets/bets'));
const BetDetail = lazy(() => import('src/pages/dashboard/bets/bet-detail'));
const CasinoCMS = lazy(() => import('src/pages/dashboard/casino-cms/casino-cms'));
const BetTicker = lazy(() => import('src/pages/dashboard/surveillance/bet-ticker/bet-ticker'));
const SuperAdminList = lazy(()=>import('src/pages/dashboard/super-admins/list'));
const SuperAdminAdd = lazy(()=>import('src/pages/dashboard/super-admins/add'));
const SuperAdminDetail = lazy(()=>import('src/pages/dashboard/super-admins/detail'));
const SuperAdminEdit = lazy(()=>import('src/pages/dashboard/super-admins/edit'));
const TransferList = lazy(()=>import('src/pages/dashboard/super-admins/transferList'));
const SiteNotification = lazy(()=>import('src/pages/dashboard/site-notifications/site-notifications'))
const Maintenance = lazy(()=>import('src/pages/dashboard/maintenance/maintenance'))
const CompanyAdminRoles = lazy(()=>import('src/pages/dashboard/roles/list'))
const CompanyAdminRoleAdd = lazy(()=>import('src/pages/dashboard/roles/add'))
const CompanyAdminRoleEdit = lazy(()=>import('src/pages/dashboard/roles/edit'))
const CompanyAdminRoleDetail = lazy(()=>import('src/pages/dashboard/roles/detail'))
// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'sports', 
        children: [
          {element: <Sports />, index:true},
          { path: ':sportId/:eventId', element: <EventDetail /> },
        ],
      },
      { path: 'bets', 
      children: [
        {element: <Bets />, index:true},
        { path: ':betId', element: <BetDetail /> },
        ],
      },
      { path: 'casino_cms', element: <CasinoCMS /> },
      {
        path: 'super_admins',
        children:[
          {path:'list',element:<SuperAdminList />},
          {path:'add',element:<SuperAdminAdd />},
          {path:'detail/:admin_id',element:<SuperAdminDetail />},
          {path:'edit/:admin_id',element:<SuperAdminEdit />},
          {path:'transfer',element:<TransferList />},
        ]
      },
      {
        path:'roles',
        children:[
          {element: <CompanyAdminRoles />, index:true},
          {path: 'add' ,element: <CompanyAdminRoleAdd />},
          {path: 'edit/:admin_id' ,element: <CompanyAdminRoleEdit />},
          {path: 'detail/:admin_id' ,element: <CompanyAdminRoleDetail />},
        ]
      },
      { path: 'surveillance', 
      children:[
        {path:'bet_ticker',element:<BetTicker />}
      ]
    },
    { path: 'site_notications', element: <SiteNotification /> },
    { path: 'maintenance', element: <Maintenance /> },
    { path: 'three', element: <PageThree /> },
      {
        path: 'group',
        children: [
          { element: <PageFour />, index: true },
          { path: 'five', element: <PageFive /> },
          { path: 'six', element: <PageSix /> },
        ],
      },
    ],
  },
];
