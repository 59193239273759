// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    analytics: `${ROOTS.DASHBOARD}/analytics`,
    bets: {
      root:`${ROOTS.DASHBOARD}/bets`,
      detail:(betId:string) =>`${ROOTS.DASHBOARD}/bets/${betId}`
    },
    users: `${ROOTS.DASHBOARD}/users`,
    super_admins: {
      root: `${ROOTS.DASHBOARD}/super_admins`,
      listing:`${ROOTS.DASHBOARD}/super_admins/list`,
      add:`${ROOTS.DASHBOARD}/super_admins/add`,
      detail:(admin_id:string) =>`${ROOTS.DASHBOARD}/super_admins/detail/${admin_id}`,
      edit:(admin_id:string) =>`${ROOTS.DASHBOARD}/super_admins/edit/${admin_id}`,
      transfer:`${ROOTS.DASHBOARD}/super_admins/transfer`
    },
    casino_cms: `${ROOTS.DASHBOARD}/casino_cms`,
    roles: {
      root:`${ROOTS.DASHBOARD}/roles`,
      add:`${ROOTS.DASHBOARD}/roles/add`,
      edit:(admin_id:string) =>`${ROOTS.DASHBOARD}/roles/edit/${admin_id}`,
      detail:(admin_id:string) =>`${ROOTS.DASHBOARD}/roles/detail/${admin_id}`
    },
    notification: `${ROOTS.DASHBOARD}/site_notications`,
    surveillance:{
      root: `${ROOTS.DASHBOARD}/surveillance`,
      bet_ticker:`${ROOTS.DASHBOARD}/surveillance/bet_ticker`
    },
    maintenance: `${ROOTS.DASHBOARD}/maintenance`,
    sports: {
      root:`${ROOTS.DASHBOARD}/sports`,
      detail:(sportId:string,eventId: string) =>`${ROOTS.DASHBOARD}/sports/${sportId}/${eventId}`
    },
    casino: `${ROOTS.DASHBOARD}/casino`,
    
  },
};
