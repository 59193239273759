import useSWR, {mutate} from 'swr';
import { useMemo } from 'react';
// utils
import axiosInstance, { fetcher, endpoints } from 'src/utils/axios';
import { ICurrency } from 'src/types/currency';

const CURRENCYURL = endpoints.currency.get

export function useGetCurrencies(){
    const { data, isLoading, error, isValidating } = useSWR(CURRENCYURL,fetcher);

    const memoizedValue = useMemo(
        () => ({
            currencies: (data?.data as ICurrency[]) || [],
            currenciesCount:data?.data?.length,
            currenciesLoading: isLoading,
            currenciesError: error,
            currenciesValidating: isValidating,
            currenciesEmpty: !isLoading && !data?.data,
        }),
        [data?.data ,error, isLoading, isValidating]
        );
    
        return memoizedValue;
}