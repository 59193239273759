import useSWR, {mutate} from 'swr';
import { useMemo } from 'react';
// utils
import axiosInstance, { fetcher, endpoints } from 'src/utils/axios';

// types
import { ISport } from 'src/types/sports';
import { ERROR_MSG } from 'src/config-global';
import { limits } from 'src/types/common';

// ----------------------------------------------------------------------
const URL = endpoints.sport.list;

const CategoryURL = endpoints.sport.listByCategory;

export function useGetSports() {

    const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

    const memoizedValue = useMemo(
        () => ({
          sports: (data?.data as ISport[]) || [],
          sportsLoading: isLoading,
          sportsError: error,
          sportsValidating: isValidating,
          sportsEmpty: !isLoading && !data?.data.length,
        }),
        [data?.data ,error, isLoading, isValidating]
      );
    
      return memoizedValue;
}

export function useGetSportsByCategory(category_id:string) {

    const { data, isLoading, error, isValidating } = useSWR(CategoryURL(category_id), fetcher);

    const memoizedValue = useMemo(
        () => ({
          sports: (data?.data as ISport[]) || [],
          sportsLoading: isLoading,
          sportsError: error,
          sportsValidating: isValidating,
          sportsEmpty: !isLoading && !data?.data.length,
        }),
        [data?.data ,error, isLoading, isValidating]
      );
    
      return memoizedValue;
}

export async function updateSport(game_id:string,game_limits:limits[],category_id:string) {
    try {
        const data = {game_limits};
        await axiosInstance.put(endpoints.sport.set_limit(game_id),data);
        if(category_id)
        {
          mutate(CategoryURL(category_id))
        }
    } catch (error) {
        if(error && error.status_message) throw error.status_message;
        else throw ERROR_MSG;
    }
    
}