import { IPermission, ISectionPermission } from "src/types/roles";


export function generatePassword(length:number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export function getSectionPermissionValues(permission_name:string,permissions:ISectionPermission[] | null | undefined){
  if(!permissions || permissions.length === 0 ) return null;
  console.log(permissions);
  return permissions.find((permission)=>permission.menu_name?.toLowerCase() === permission_name.toLowerCase());
}

export function getPermissionValues(permission_name:string,permissions:IPermission[]| null | undefined){
  if(!permissions || permissions.length === 0 ) return null;
  console.log(permissions);
  return permissions.find((permission)=>permission.permission_name?.toLowerCase() === permission_name.toLowerCase());
}