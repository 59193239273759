


export const SECTIONS = {
    SPORTS:"sports",
    SURVEILLANCE:"surveillance",
    ROLES:"roles",
    NOTIFICATIONS:"notification",
    CASINOCMS:"casino cms",
    SUPERADMINS:"site admins",
    USERS:"users",
    BETS:"bets",

}

export const SPORT_FEATURES = {
    LIMITS:"Sport limits",
    LEAGUELIMITS:"League Limits",
    EVENTLIMITS:"Event Limits",
    EVENTVOID:"Event Void Bets",
    MARKETVOID:"Marketwise Void Bets",
    RESULTDECLARE:"Result Declare",
    RESULTVERIFY:"Result Verify",
}

export const BET_FEATURES = {
    BETVOID : "Void Bets"
}

export const SURVEILLANCE_FEATURES = {
    BETVOID : "Void Bets"
}