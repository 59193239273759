import { configureStore } from '@reduxjs/toolkit'
import CategoryReducer from "./reducers/category-slice"
import CurrencyReducer from "./reducers/currency-slice"

const store = configureStore({
    reducer:{
        categories:CategoryReducer,
        currencies:CurrencyReducer
    },
})


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;