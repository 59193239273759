import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
import { IS_PROD } from 'src/config-global';
import { useAuthContext } from 'src/auth/hooks';
import { ISectionPermission } from 'src/types/roles';
import { getSectionPermissionValues } from 'src/utils/custom-functions';
import { SECTIONS } from 'src/_mock/_sections';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  analytics: icon('ic_analytics'),
  bets: icon('ic_bets'),
  user: icon('ic_user'),
  site_admins: icon('ic_site_admins'),
  casino_cms: icon('ic_casino_cms'),
  roles: icon('ic_roles'),
  notification: icon('ic_notification'),
  sports: icon('ic_sports'),
  casino: icon('ic_casino'),
  surveillance:icon('ic_surveillance'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { user } = useAuthContext();
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'overview',
        items: [
          { title: 'Analytics', path: paths.dashboard.analytics, icon: ICONS.analytics,roles:IS_PROD ? ['user'] :["admin"],restrict_access:true },

          { title: 'Bets', path: paths.dashboard.bets.root, icon: ICONS.bets,roles:IS_PROD ? ['user','admin'] :["admin"],
            restrict_access:getSectionPermissionValues(SECTIONS.BETS,user?.permissions)?.restrict_access },

          { title: 'Users', path: paths.dashboard.users, icon: ICONS.user,roles:IS_PROD ? ['user'] :["admin"],
            restrict_access:getSectionPermissionValues(SECTIONS.USERS,user?.permissions)?.restrict_access || true },

          { title: 'Super Admins', path: paths.dashboard.super_admins.root, icon: ICONS.site_admins,
            children:[
              { title: 'Member Listing', path: paths.dashboard.super_admins.listing },
              { title: 'Transfer', path: paths.dashboard.super_admins.transfer },
            ],roles:IS_PROD ? ['user'] :["admin"],
            restrict_access:getSectionPermissionValues(SECTIONS.SUPERADMINS,user?.permissions)?.restrict_access || true
          },

          { title: 'Casino CMS', path: paths.dashboard.casino_cms, icon: ICONS.casino_cms,roles:IS_PROD ? ['user','admin'] :["admin"],
            restrict_access:getSectionPermissionValues(SECTIONS.CASINOCMS,user?.permissions)?.restrict_access
          },

          { title: 'Roles', path: paths.dashboard.roles.root, icon: ICONS.roles,roles:IS_PROD ? ['user'] :["admin"],
            restrict_access:getSectionPermissionValues(SECTIONS.ROLES,user?.permissions)?.restrict_access || true
          },

          { title: 'Notifications', path: paths.dashboard.notification, icon: ICONS.notification,roles:IS_PROD ? ['user'] :["admin"],
            restrict_access:getSectionPermissionValues(SECTIONS.NOTIFICATIONS,user?.permissions)?.restrict_access || true
          },

          { title: 'Surveillance', path: paths.dashboard.surveillance.root, icon: ICONS.surveillance,
            children:[
              { title: 'Bet Ticker', path: paths.dashboard.surveillance.bet_ticker },
            ],roles:IS_PROD ? ['user','admin'] :["admin"],
            restrict_access:getSectionPermissionValues(SECTIONS.SURVEILLANCE,user?.permissions)?.restrict_access
          },

          { title: 'Maintenance Mode', path: paths.dashboard.maintenance, icon: ICONS.site_admins,roles:IS_PROD ? ['user'] :["admin"],restrict_access: true }
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'events',
        items: [
          { title: 'Sports', path: paths.dashboard.sports.root, icon: ICONS.sports,
            restrict_access:getSectionPermissionValues(SECTIONS.SPORTS,user?.permissions)?.restrict_access
          },
          // { title: 'Casino', path: paths.dashboard.casino, icon: ICONS.casino },
        ],
      },
    ],
    [user]
  );

  return data;
}
