import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICategory } from 'src/types/category'
import { ISport } from 'src/types/sports';


type ICategorySlice = {
    categories : ICategory[],
    sports: ISport[]
}

const initialState: ICategorySlice = {
    categories:[],
    sports:[],
};

export const categorySlice = createSlice({
    name:"categories",
    initialState,
    reducers:{
        updateCategories:(state,action:PayloadAction<ICategory[]>) =>{
            state.categories = action.payload;
        },
        updateSports:(state,action:PayloadAction<ISport[]>) =>{
            state.sports = action.payload;
        },
    }
})


export const { updateCategories,updateSports } = categorySlice.actions;

export default categorySlice.reducer;